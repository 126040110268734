import {
  Lightbox,
  IconButton,
  createIcon,
  SlideImage,
  SlideVideo,
  useLightboxState,
  addToolbarButton,
} from "yet-another-react-lightbox";
import {
  Captions,
  Counter,
  Fullscreen,
  Slideshow,
  Video,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { Download, SpinnerIcon } from "../../assets/svg";
import { useState } from "react";

type TDownloadFileProps = {
  fileName: string;
  url: string;
};

type CarroselProps = {
  slides: (SlideImage | SlideVideo)[];
  open: boolean;
  onDownloadFile: (index: number) => Promise<TDownloadFileProps>;
  onClose?: () => void;
};

const DownloadIcon = createIcon(
  "Download",
  <Download width={22} height={22} />
);

const DownloadToast = () => {
  return (
    <div className="w-[300px] bg-blue-500 fixed right-0 bottom-0 mr-5 mb-5 flex items-center z-10000">
      <div className="h-full flex items-center justify-center p-5">
        <SpinnerIcon width={36} height={36} color="white" outline="black" />
      </div>

      <span className="text-white font-century-gothic-bold">
        Preparando o download...
      </span>
    </div>
  );
};

const Carrosel = (props: CarroselProps) => {
  const { slides, open, onDownloadFile, onClose } = props;
  const [showDownloadToast, setShowDownloadToast] = useState(false);
  const [fileIndex, setFileIndex] = useState<number>(-1);

  const handleClose = () => {
    setFileIndex(-1);

    if (onClose) onClose();
  };

  function DownloadButton() {
    const { currentIndex } = useLightboxState();

    const downloadFileOnClick = async (filename: string, url: string) => {
      setShowDownloadToast(true);

      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);

        const linkElement = document.createElement("a");

        linkElement.style.display = "none";
        linkElement.href = fileUrl;
        linkElement.download = filename;

        document.body.appendChild(linkElement);

        linkElement.click();

        document.body.removeChild(linkElement);

        window.URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error("Error downloading", JSON.stringify(error));
      } finally {
        setShowDownloadToast(false);
      }
    };

    const handleDownload = async () => {
      const { fileName, url } = await onDownloadFile(currentIndex);

      await downloadFileOnClick(fileName, url);
    };

    return (
      <IconButton
        label="Download"
        icon={DownloadIcon}
        onClick={handleDownload}
        disabled={showDownloadToast}
      />
    );
  }

  function CustomDownloadPlugin({ augment }: { augment: any }) {
    augment(({ toolbar, ...restProps }: { toolbar: any }) => ({
      toolbar: addToolbarButton(toolbar, "download", <DownloadButton />),
      ...restProps,
    }));
  }

  return (
    <>
      <Lightbox
        slides={slides}
        open={open}
        index={fileIndex}
        close={handleClose}
        plugins={[
          Captions,
          Counter,
          CustomDownloadPlugin,
          Fullscreen,
          Slideshow,
          Video,
        ]}
        captions={{
          descriptionTextAlign: "center",
          showToggle: true,
        }}
      />

      {showDownloadToast && <DownloadToast />}
    </>
  );
};

export default Carrosel;
export type { TDownloadFileProps };
