import { TQuery, TQueryDocument } from "@libs/firebase/Firebase.types";
import FirebaseFirestoreService from "../libs/firebase/FirebaseFirestoreService";
import {
  TDocument,
  TPhotoChallengeDone,
  TPhotoChallengeResult,
  TQuizRank,
  TQuizResult,
  TTeamMatchStats,
  TTeamMatchResult,
} from "./types";
import * as DigitalOceanSpaces from "./digital_ocean/spaces";
import dayjs from "dayjs";
import {
  PHOTO_CHALLENGES_DONE_COLLECTION,
  QUIZ_RESULTS_COLLECTION,
  TEAM_MATCH_RESULTS_COLLECTION,
} from "../constants";
import * as UploadsService from "./uploads";

const getQuizResults = async (eventId: string) => {
  const queries: TQuery[] = [
    {
      field: "eventId",
      condition: "==",
      value: eventId,
    },
  ];

  const queryArgs: TQueryDocument = {
    collection: QUIZ_RESULTS_COLLECTION,
    queries,
    orderByField: "createdAt",
    orderByDirection: "desc",
  };

  const response = await FirebaseFirestoreService.readDocuments(queryArgs);
  const quizResults: TQuizResult[] = response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  return quizResults;
};

const getPhotoChallengesDone = async (eventId: string) => {
  const queries: TQuery[] = [
    {
      field: "eventId",
      condition: "==",
      value: eventId,
    },
  ];

  const queryArgs: TQueryDocument = {
    collection: PHOTO_CHALLENGES_DONE_COLLECTION,
    queries,
    orderByField: "createdAt",
    orderByDirection: "desc",
  };

  const response = await FirebaseFirestoreService.readDocuments(queryArgs);
  const photoChallengesDone: TPhotoChallengeDone[] = response.docs.map(
    (doc: TDocument) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    }
  );

  const uploads = await UploadsService.getUploadsByEventId(eventId);
  const uploadsMap: { [key: string]: (typeof uploads)[0] } = uploads.reduce(
    (acc, upload) => ({ ...acc, [upload.id as string]: upload }),
    {}
  );

  photoChallengesDone.forEach((photoChallenge) => {
    if (uploadsMap[photoChallenge.uploadId]) {
      photoChallenge.upload = uploadsMap[photoChallenge.uploadId];
    }
  });

  return photoChallengesDone;
};

const getTeamMatchResults = async (eventId: string) => {
  const queries: TQuery[] = [
    {
      field: "eventId",
      condition: "==",
      value: eventId,
    },
  ];

  const queryArgs: TQueryDocument = {
    collection: TEAM_MATCH_RESULTS_COLLECTION,
    queries,
    orderByField: "createdAt",
    orderByDirection: "desc",
  };

  const response = await FirebaseFirestoreService.readDocuments(queryArgs);
  const teamMatchResults: TTeamMatchResult[] = response.docs.map(
    (doc: TDocument) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    }
  );

  return teamMatchResults;
};

const computeQuizRank = async (
  quizResults: TQuizResult[]
): Promise<TQuizRank[]> => {
  const orderedResults = quizResults.sort((resultA, resultB) => {
    if (resultA.score !== resultB.score) {
      return resultA.score > resultB.score ? -1 : 1;
    }

    if (
      resultA.score === resultB.score &&
      resultA.timeSpentInSeconds !== resultB.timeSpentInSeconds
    ) {
      return resultA.timeSpentInSeconds < resultB.timeSpentInSeconds ? -1 : 1;
    }

    return resultA.createdAt < resultB.createdAt ? -1 : 1;
  });

  const rankedResults = orderedResults.map((result, index) => ({
    ...result,
    rank: index + 1,
  }));

  return rankedResults;
};

const computePhotoChallengeRank = async (
  photoChallengesDone: TPhotoChallengeDone[]
) => {
  const groupByGuests = photoChallengesDone.reduce(
    (acc: { [key: string]: TPhotoChallengeDone[] }, photoChallenge) => {
      const index = photoChallenge.guestId;

      if (!acc[index]) {
        acc[index] = [];
      }

      acc[index].push(photoChallenge);

      return acc;
    },
    {}
  );

  const resultsPromises = await Object.keys(groupByGuests).map(
    async (guestId) => {
      const entries = groupByGuests[guestId];

      const orderedAnswersByCreationDate = entries.sort(
        (photoChallengeA, photoChallengeB) => {
          const aCreatedAtConverted = dayjs(photoChallengeA.createdAt);
          const bCreatedAtConverted = dayjs(photoChallengeB.createdAt);

          return aCreatedAtConverted.isBefore(bCreatedAtConverted) ? -1 : 1;
        }
      );

      const lastAnswer = orderedAnswersByCreationDate[0];
      let avatar;

      if (lastAnswer.guestPhotoStoragePath) {
        avatar = await DigitalOceanSpaces.getFileUrl(
          lastAnswer.guestPhotoStoragePath
        );
      }

      const result: TPhotoChallengeResult = {
        guestId,
        guestName: lastAnswer.guestName as string,
        avatar,
        rank: 0,
        lastAnswerAt: lastAnswer.createdAt,
        entries: orderedAnswersByCreationDate,
        totalAnswers: orderedAnswersByCreationDate.length,
      };

      return result;
    }
  );

  const results = await Promise.all(resultsPromises);

  const orderedResults = results.sort((guestA, guestB) => {
    if (guestA.totalAnswers !== guestB.totalAnswers) {
      return guestA.totalAnswers > guestB.totalAnswers ? -1 : 1;
    }

    const guestALastAnswerDate = dayjs(guestA.lastAnswerAt);
    const guestBLastAnswerDate = dayjs(guestB.lastAnswerAt);

    return guestALastAnswerDate.isBefore(guestBLastAnswerDate) ? -1 : 1;
  });

  const rankedResults = orderedResults.map((result, index) => ({
    ...result,
    rank: index + 1,
  }));

  return rankedResults;
};

const generateTeamMatchStats = async (
  teamMatchResults: TTeamMatchResult[]
): Promise<TTeamMatchStats> => {
  const groupByTeams = teamMatchResults.reduce(
    (
      acc: { teamA: TTeamMatchResult[]; teamB: TTeamMatchResult[] },
      teamMatchResult
    ) => {
      const team =
        teamMatchResult.teamAScore > teamMatchResult.teamBScore
          ? "teamA"
          : "teamB";

      if (!acc[team]) {
        acc[team] = [];
      }

      acc[team].push(teamMatchResult);

      return acc;
    },
    { teamA: [], teamB: [] }
  );

  return {
    totalTeamA: groupByTeams.teamA.length,
    totalTeamB: groupByTeams.teamB.length,
    totalGuests: teamMatchResults.length,
    teamAGuests: groupByTeams.teamA,
    teamBGuests: groupByTeams.teamB,
  };
};

const service = {
  getQuizResults,
  getPhotoChallengesDone,
  getTeamMatchResults,
  computeQuizRank,
  computePhotoChallengeRank,
  generateTeamMatchStats,
};

export default service;
