import { useState, useMemo } from "react";
import { teamMatchSchema } from "assets/constants/teamMatchQuestions";
import { TEvent, TTeamMatchQuestion } from "@services/types";
import { useOutletContext } from "react-router-dom";
import { updateEvent } from "../../services/events";
import { TEAM_MATCH_FEATURE_FLAG } from "../../constants";
import { Button } from "../ui";
import FeatureToggle from "./FeatureToggle";

const TEAM_MATCH_QUESTIONS_LIMIT = 15;

export default function TeamMatch() {
  const { event } = useOutletContext() as { event: TEvent };

  const featureEnabled = event.betaFeatures?.includes(TEAM_MATCH_FEATURE_FLAG);
  const [isTeamMatchEnabled, setIsTeamMatchEnabled] = useState(
    featureEnabled as boolean
  );

  const currentActiveQuestions =
    event.teamMatchGame?.questions?.map((q) => q.id) ?? [];
  const [activeQuestions, setActiveQuestions] = useState<string[]>(
    currentActiveQuestions
  );

  const teamMatchQuestions = useMemo(() => {
    return teamMatchSchema.map((questionSchema) => {
      const teamMatchQuestion = event.teamMatchGame?.questions.find(
        (q) => q.id === questionSchema.id
      );

      if (!teamMatchQuestion) {
        return {
          ...questionSchema,
          teamA: "",
          teamB: "",
        };
      }

      const { teamA, teamB } = teamMatchQuestion;

      return {
        ...questionSchema,
        teamA,
        teamB,
      };
    });
  }, [event.teamMatchGame]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isTeamMatchEnabled) {
      const questions: TTeamMatchQuestion[] = [];

      activeQuestions.forEach((questionId) => {
        const questionSchema = teamMatchQuestions.find(
          (q) => q.id === questionId
        );

        if (!questionSchema) {
          console.error(`Question with id ${questionId} not found!`);
          return;
        }

        // fetch the inputs
        const teamAInput = e.currentTarget.elements.namedItem(
          `${questionId}_team_a`
        ) as HTMLInputElement;
        const teamBInput = e.currentTarget.elements.namedItem(
          `${questionId}_team_b`
        ) as HTMLInputElement;

        questions.push({
          ...questionSchema,
          teamA: teamAInput.value,
          teamB: teamBInput.value,
        });
      });

      const betaFeatures = new Set(event.betaFeatures);
      betaFeatures.add(TEAM_MATCH_FEATURE_FLAG);

      event.betaFeatures = Array.from(betaFeatures);
      event.teamMatchGame = {
        teamAName: "Time da Noiva",
        teamAPhotoStoragePath: "",
        teamBName: "Time do Noivo",
        teamBPhotoStoragePath: "",
        questions,
      };
    } else {
      event.betaFeatures = event.betaFeatures?.filter(
        (f) => f !== TEAM_MATCH_FEATURE_FLAG
      );
    }

    // Save teamMatch
    await updateEvent(event);
  };

  const handleQuestionToggle = (questionId: string) => {
    setActiveQuestions((prev) => {
      if (prev.includes(questionId)) {
        return prev.filter((id) => id !== questionId);
      }
      if (prev.length >= TEAM_MATCH_QUESTIONS_LIMIT) {
        alert(
          `Você atingiu o limite máximo de ${TEAM_MATCH_QUESTIONS_LIMIT} questões!`
        );
        return prev;
      }
      return [...prev, questionId];
    });
  };

  return (
    <div className="flex flex-col items-start justify-start gap-4 w-full">
      <h1 className="w-full flex items-center gap-3">
        Team Match
        <FeatureToggle
          checked={isTeamMatchEnabled}
          onChange={setIsTeamMatchEnabled}
        />
      </h1>

      <div className="flex items-center gap-2">
        <h3 className="">
          {activeQuestions.length}/{TEAM_MATCH_QUESTIONS_LIMIT} questões
          selecionadas
        </h3>
      </div>
      <form
        className="flex flex-col justify-start gap-4 w-full"
        onSubmit={onSubmit}
      >
        {teamMatchQuestions.map((question, index) => (
          <TeamMatchQuestion
            key={question.id}
            question={question}
            index={index + 1}
            setActiveQuestions={handleQuestionToggle}
            isActive={activeQuestions.includes(question.id)}
            disabled={!isTeamMatchEnabled}
          />
        ))}

        <div className="pt-5">
          <Button type="submit">Salvar</Button>
        </div>
      </form>
    </div>
  );
}

const TeamMatchQuestion = ({
  question,
  index,
  setActiveQuestions,
  isActive,
  disabled = false,
}: {
  question: TTeamMatchQuestion;
  index: number;
  setActiveQuestions: (questionId: string) => void;
  isActive: boolean;
  disabled?: boolean;
}) => {
  const { id, text, teamA, teamB } = question;

  return (
    <fieldset
      className="flex flex-col justify-start gap-2 w-full"
      disabled={disabled}
    >
      <legend className="flex items-center justify-start gap-4 w-full">
        <input
          type="checkbox"
          checked={isActive}
          onChange={(e) => setActiveQuestions(id)}
          className="w-6 h-6"
          disabled={disabled}
        />
        <h2 className="font-bold">{`${index}. ${text}`}</h2>
      </legend>

      <div className="flex items-center justify-start gap-2 w-full">
        <div className="flex flex-col grow">
          <h3 className="font-bold">Resposta Time A (Noiva)</h3>
          <input type="text" name={`${id}_team_a`} defaultValue={teamA} />
        </div>

        <div className="flex flex-col grow">
          <h3 className="font-bold">Resposta Time B (Noivo)</h3>
          <input type="text" name={`${id}_team_b`} defaultValue={teamB} />
        </div>
      </div>
    </fieldset>
  );
};
