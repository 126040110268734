import FirebaseFirestore from "@firebase/firestore-types";

export enum EEventKind {
  Recorder = "recorder",
  Uploader = "uploader",
}

export enum EUploadMenuItem {
  foodMenu = "food_menu",
  schedule = "schedule",
}

export const EVENT_FEATURES = {
  recorder: "Vídeo Recado",
  uploader: "Galeria da Festa",
};

export const UPLOAD_MENU_ITEMS = {
  foodMenu: "Cardápio",
  schedule: "Programação",
};

export type TEvent = {
  id?: string;
  kind?: EEventKind;
  features?: string[];
  title: string;
  code?: string;
  date: string;
  releaseDate: string;
  realtimeGallery?: boolean;
  publicGallery?: boolean;
  bannerUrl?: string;
  languages?: string[];
  city?: string;
  state?: string;
  country?: string;
  totalGuests?: string;
  contacts?: TContact[];
  searchIndex?: string[];
  customization?: TCustomization;
  compressed_video_collection_url?: string;
  owners?: string[];
  betaFeatures?: string[];
  quiz?: TQuizQuestion[];
  bingo?: TBingoChallenge[];
  photoChallenge?: TPhotoChallenge[];
  teamMatchGame?: TTeamMatchGame;
  menuUploads?: TUploadMenuItem[];
  createdAt?: string;
  updatedAt?: string;
};

export type TVideo = {
  id?: string;
  eventId: string;
  eventCode: string;
  status: string;
  thumbnailFileName: string;
  thumbnailUrl: string;
  videoFileExtension: string;
  videoFileName: string;
  videoUrl: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TUpload = {
  id?: string;
  eventId: string;
  eventCode: string;
  status: string;
  fileName: string;
  fileUrl: string;
  fileExt: string;
  fileSize: number;
  fileStoragePath: string;
  convertedFileStoragePath?: string;
  optimizedFileStoragePath?: string;
  thumbnailStoragePath?: string;
  guestId?: string;
  guestName?: string;
  tags?: string[];
  createdAt?: string;
  updatedAt?: string;
};

export type TEventWithUploads = TEvent & { uploads: TUpload[] };

type TCustomization = {
  theme: TThemeProps;
  template: TTemplateProps;
};

type TThemeProps = {
  name: string;
  themeColor: string;
  backgroundColor: string;
  cardShape: string;
  buttonShape: string;
};

export type TTemplateProps = {
  name: string;
  titleFontSize?: string;
  titleFontFamily?: string;
  titleOneText?: string;
  titleTwoText?: string;
  subtitleText?: string;
};

export type TContact = {
  name: string;
  email?: string;
  whatsapp?: string;
  instagram?: string;
  reference?: string;
  notify?: string;
  role?: string;
  owner?: boolean;
};

export type TLog = {
  id?: string;
  eventId: string;
  message: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TBingoChallenge = {
  id: string;
  text: string;
};

export type TQuizQuestion = {
  id: string;
  text: string;
  options: string[];
  correct: string;
};

export type TQuizResult = {
  id?: string;
  eventId: string;
  eventCode: string;
  guestId: string;
  guestName: string | undefined;
  guestPhotoStoragePath: string | undefined;
  quiz: TQuizQuestion[];
  answers: Record<string, string>;
  score: number;
  totalQuestions: number;
  timeSpentInSeconds: number;
  createdAt: string;
};

export type TQuizRank = TQuizResult & {
  rank: number;
  avatar?: string | undefined | null;
};

export type TPhotoChallenge = {
  id: string;
  text: string;
};

export type TPhotoChallengeDone = {
  id?: string;
  eventId: string;
  eventCode: string;
  uploadId: string;
  upload?: TUpload;
  guestId: string;
  guestName?: string;
  guestPhotoStoragePath?: string;
  challengeId: string;
  challengeText: string;
  photoStoragePath: string;
  photoUrl?: string;
  createdAt: string;
};

export type TPhotoChallengeResult = {
  guestId: string;
  guestName: string;
  avatar: string | undefined | null;
  lastAnswerAt: string;
  entries: TPhotoChallengeDone[];
  totalAnswers: number;
  rank: number;
};

export type TTeamMatchGame = {
  teamAName: string;
  teamAPhotoStoragePath: string;
  teamBName: string;
  teamBPhotoStoragePath: string;
  questions: TTeamMatchQuestion[];
};

export type TTeamMatchQuestion = {
  id: string;
  text: string;
  teamA: string;
  teamB: string;
};

export type TTeamMatchAnswer = {
  team: string;
  value: string;
};

export type TTeamMatchResult = {
  id?: string;
  eventId: string;
  eventCode: string;
  guestId: string;
  guestName: string | undefined;
  guestPhotoStoragePath: string | undefined;
  questions: TTeamMatchQuestion[];
  answers: Record<string, TTeamMatchAnswer>;
  teamAScore: number;
  teamBScore: number;
  totalQuestions: number;
  timeSpentInSeconds: number;
  createdAt: string;
};

export type TTeamMatchStats = {
  totalTeamA: number;
  totalTeamB: number;
  totalGuests: number;
  teamAGuests: TTeamMatchResult[];
  teamBGuests: TTeamMatchResult[];
};

export type TUploadMenuItem = {
  id: EUploadMenuItem;
  storagePath?: string;
  enabled: boolean;
};

export type TDocument = FirebaseFirestore.DocumentData;
