import { TPhotoChallengeDone } from "@services/types";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { formatDateTimeShort } from "utils/utils";
import userProfile from "../../../assets/images/user-profile.png";
import { SlideImage, SlideVideo } from "yet-another-react-lightbox";
import * as DigitalOceanSpaces from "../../../services/digital_ocean/spaces";
import Carrosel, {
  TDownloadFileProps,
} from "../../../components/gallery/Carrosel";
import { TCustomerEventPhotoChallengeLoader } from "@services/customer";

export default function PhotoChallenge() {
  const { event, results } =
    useLoaderData() as TCustomerEventPhotoChallengeLoader;
  const [guestSelected, setGuestSelected] = useState<number>();
  const [slides, setSlides] = useState<(SlideImage | SlideVideo)[]>([]);

  const handleResultClick = useCallback(
    (index: number) => () => setGuestSelected(index),
    []
  );

  const handleDownloadFile = useCallback(
    async (slideIndex: number): Promise<TDownloadFileProps> => {
      const photoChallengeDone =
        results[guestSelected as number].entries[slideIndex];

      const { guestId, guestName, challengeText, upload } = photoChallengeDone;

      const photoStoragePath = (upload?.convertedFileStoragePath ||
        upload?.fileStoragePath) as string;
      const photoStoragePathEncoded = encodeURIComponent(photoStoragePath);
      const photoExt = photoStoragePath.split(".")[1];
      const fileName = `${guestName || guestId} - ${challengeText}.${photoExt}`;
      const url = await DigitalOceanSpaces.getFileUrl(photoStoragePathEncoded);
      return { url, fileName };
    },
    [guestSelected, results]
  );

  const handleCloseCarrosel = useCallback(() => {
    setGuestSelected(undefined);
    setSlides([]);
  }, []);

  useEffect(() => {
    const loadSlidesAsync = async () => {
      const result = results[guestSelected as number];
      let slides: Promise<SlideImage>[] = [];

      if (result) {
        slides = await result.entries.map(
          async (photoChallengeDone: TPhotoChallengeDone) => {
            const { upload, challengeText } = photoChallengeDone;

            const photoStoragePath =
              upload?.optimizedFileStoragePath || upload?.fileStoragePath;
            const photoStoragePathEncoded = encodeURIComponent(
              photoStoragePath as string
            );
            const src = await DigitalOceanSpaces.getFileUrl(
              photoStoragePathEncoded
            );

            return {
              type: "image",
              src,
              alt: challengeText,
              title: challengeText,
              description: challengeText,
            } as SlideImage;
          }
        );
      }

      setSlides(await Promise.all(slides));
    };

    loadSlidesAsync();
  }, [guestSelected, results]);

  return (
    <div className="max-w-screen-xl w-full mx-auto pb-16">
      <div className="flex flex-col justify-center items-center gap-10 mb-10 px-3 relative">
        <h1 className="text-3xl text-center font-playfair-display font-normal">
          Foto Desafio
        </h1>
        <h3 className="text-base text-center font-century-gothic-bold text-gray-600">
          Veja quem ficou em primeiro lugar!
        </h3>
        <div className="flex flex-col divide-y divide-gray-200 bg-white rounded-2xl shadow-md md:w-9/12">
          {results.map((result, index) => (
            <div
              key={result.guestId}
              className="flex flex-row gap-8 p-4 items-center hover:bg-gray-100 cursor-pointer"
              onClick={handleResultClick(index)}
            >
              <div className="flex flex-row gap-4 items-center rounded-full bg-primary-500 w-16 h-16 justify-center">
                <p className="text-3xl font-century-gothic-bold text-white">
                  {result.rank}
                </p>
              </div>
              <div className="flex flex-col gap-2 grow">
                <h2>{result.guestName}</h2>
                <div className="flex flex-row gap-2 justify-between">
                  <p className="text-base text-gray-600">
                    Desafios realizados: {result.totalAnswers}/
                    {event.photoChallenge?.length}
                  </p>
                  <p className="text-base text-gray-600">
                    {formatDateTimeShort(result.lastAnswerAt)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center rounded-2xl">
                <img
                  src={result.avatar || userProfile}
                  alt={result.guestName}
                  className="rounded-2xl h-20 w-20 object-cover"
                />
              </div>
            </div>
          ))}
        </div>

        <Carrosel
          slides={slides}
          open={guestSelected !== undefined}
          onClose={handleCloseCarrosel}
          onDownloadFile={handleDownloadFile}
        />
      </div>
    </div>
  );
}
