import { TUpload } from "@services/types";
import { filesize } from "filesize";
import { useCallback, useEffect, useState } from "react";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";

type Props = {
  upload: TUpload;
  uploadIdx: number;
};

type FileUrl = {
  url: string;
  thumbnailUrl: string;
};

const fetchUrl = async (key: string) => {
  return DigitalOceanSpaces.getFileUrl(encodeURIComponent(key));
};

export default function UploadListItem({ upload, uploadIdx }: Props) {
  const [file, setFile] = useState<FileUrl | null>(null);
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false);

  const onDownloadFile = useCallback(async () => {
    const response = await fetch(file?.url as string);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = upload.fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setIsDownloaded(true);
  }, [file, upload.fileName, setIsDownloaded]);

  useEffect(() => {
    const getFileUrls = async () => {
      if (!upload.fileStoragePath) {
        return "";
      }

      const url = await fetchUrl(upload.fileStoragePath as string);
      const thumbnailUrl = await fetchUrl(
        upload.thumbnailStoragePath as string
      );

      setFile({ url, thumbnailUrl });
    };

    getFileUrls();
  }, [upload]);

  return (
    <div className="w-full relative no-underline appearance-none flex items-center border border-gray-300 p-1 gap-4 bg-white rounded-md">
      <div className="w-32 h-32 bg-gray-200 overflow-hidden">
        {file ? (
          <img
            className="h-full w-full object-cover rounded-md"
            src={file.thumbnailUrl}
            alt={upload.fileName}
          />
        ) : (
          <p className="h-full w-full flex items-center justify-center">
            Sem banner
          </p>
        )}
      </div>
      {/* Column 1 */}
      <div className="flex flex-col items-start self-stretch justify-between">
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">ID</h6>
          <p>{upload.id}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Data de criação</h6>
          <p>{upload.createdAt}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Data de atualização</h6>
          <p>{upload.updatedAt}</p>
        </div>
      </div>
      {/* Column 2 */}
      <div className="flex flex-col items-start self-stretch justify-between">
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Autor</h6>
          <p>{upload.guestName && `${upload.guestName} (${upload.guestId})`}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Nome do arquivo</h6>
          <p>{upload.fileName}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Tags</h6>
          <p>{upload.tags?.join(", ")}</p>
        </div>
      </div>
      {/* Action Column */}
      <div className="flex flex-col items-start self-stretch ml-auto px-2 justify-between">
        <h6 className="w-full text-right text-lg text-gray-950 font-semibold">
          #{uploadIdx}
        </h6>
        <div className="w-full flex justify-end">
          <p className="font-medium text-base">{filesize(upload.fileSize)}</p>
        </div>
        <button
          onClick={onDownloadFile}
          className={`${
            isDownloaded
              ? "bg-green-500 hover:bg-green-600"
              : "bg-red-400 hover:bg-red-500"
          } button no-underline appearance-none text-white py-2 px-4 rounded-md text-center font-semibold`}
        >
          {isDownloaded ? "Baixado" : "Baixar arquivo"}
        </button>
      </div>
    </div>
  );
}
