import { ActionFunctionArgs } from "react-router-dom";
import { getEventByCode } from "./events";
import { getUploadsByEventId } from "./uploads";
import GamesService from "../services/games";
import * as DigitalOceanSpaces from "../services/digital_ocean/spaces";
import {
  TEvent,
  TQuizRank,
  TQuizResult,
  TTeamMatchResult,
  TTeamMatchStats,
} from "./types";

export const eventLoader = async ({ params }: ActionFunctionArgs) => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  return { ...event };
};

export const eventWithUploadsLoader = async ({
  params,
}: ActionFunctionArgs) => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  const uploads = await getUploadsByEventId(event.id as string);

  return { ...event, uploads };
};

export const eventWithUploadsForPublicGalleryLoader = async ({
  params,
}: ActionFunctionArgs) => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  if (!event.publicGallery) {
    throw new Error("Public gallery not found");
  }

  const uploads = await getUploadsByEventId(event.id as string);

  return { ...event, uploads };
};

export type TCustomerEventQuizLoader = {
  event: TEvent;
  quizResults: TQuizResult[];
  quizRank: TQuizRank[];
};

export const eventQuizLoader = async ({
  params,
}: ActionFunctionArgs): Promise<TCustomerEventQuizLoader> => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  const quizResults = await GamesService.getQuizResults(event.id as string);
  const quizRank = await GamesService.computeQuizRank(quizResults);

  for (const rank of quizRank) {
    if (rank.guestPhotoStoragePath) {
      rank.avatar = await DigitalOceanSpaces.getFileUrl(
        rank.guestPhotoStoragePath
      );
    }
  }

  return { event, quizResults, quizRank };
};

export type TCustomerEventTeamMatchLoader = {
  event: TEvent;
  teamMatchResults: TTeamMatchResult[];
  teamMatchStats: TTeamMatchStats;
};

export const eventTeamMatchLoader = async ({
  params,
}: ActionFunctionArgs): Promise<TCustomerEventTeamMatchLoader> => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  const teamMatchResults = await GamesService.getTeamMatchResults(
    event.id as string
  );

  const teamMatchStats = await GamesService.generateTeamMatchStats(
    teamMatchResults
  );

  return { event, teamMatchResults, teamMatchStats };
};

export type TCustomerEventPhotoChallengeLoader = {
  event: TEvent;
  photoChallengesDone: any[];
  results: any[];
};

export const eventPhotoChallengeLoader = async ({
  params,
}: ActionFunctionArgs) => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  const photoChallengesDone = await GamesService.getPhotoChallengesDone(
    event.id as string
  );

  const results = await GamesService.computePhotoChallengeRank(
    photoChallengesDone
  );

  return { event, photoChallengesDone, results };
};
