import { Close } from "assets/svg";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import * as DigitalOceanSpaces from "../../../services/digital_ocean/spaces";
import { TTeamMatchResult } from "../../../services/types";
import userProfile from "../../../assets/images/user-profile.png";
import { TCustomerEventTeamMatchLoader } from "@services/customer";

export default function TeamMatch() {
  const { event, teamMatchStats } =
    useLoaderData() as TCustomerEventTeamMatchLoader;
  const {
    teamAName = "Time Noiva",
    teamBName = "Time Noivo",
    teamAPhotoStoragePath,
    teamBPhotoStoragePath,
  } = event?.teamMatchGame || {};
  const [teamAImage, setTeamAImage] = useState<string | undefined>();
  const [teamBImage, setTeamBImage] = useState<string | undefined>();

  useEffect(() => {
    const fetchImages = async () => {
      if (teamAPhotoStoragePath) {
        const url = await DigitalOceanSpaces.getFileUrl(teamAPhotoStoragePath);
        setTeamAImage(url);
      }

      if (teamBPhotoStoragePath) {
        const url = await DigitalOceanSpaces.getFileUrl(teamBPhotoStoragePath);
        setTeamBImage(url);
      }
    };

    fetchImages();
  }, [teamAPhotoStoragePath, teamBPhotoStoragePath]);

  return (
    <div className="max-w-screen-xl w-full mx-auto pb-16">
      <div className="flex flex-col justify-center items-center gap-10 mb-10 px-10 relative">
        <h1 className="text-3xl text-center font-playfair-display font-normal">
          Chapéu Seletor
        </h1>
        <h3 className="text-base text-center font-century-gothic-bold text-gray-600">
          Descubra quem tem o time com mais convidados!
        </h3>

        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-12 items-center">
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-4 items-center rounded-2xl relative">
                <img
                  src={teamAImage || "https://picsum.photos/200"}
                  alt={teamAName}
                  className="rounded-2xl w-60 object-cover aspect-[3/4]"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-white/80 px-2 py-4">
                  <h3 className="text-2xl text-center font-century-gothic-bold text-gray-600">
                    {teamAName}
                  </h3>
                </div>
              </div>
              <p className="text-5xl font-century-gothic-bold text-gray-600 text-center">
                {(
                  (teamMatchStats.totalTeamA / teamMatchStats.totalGuests) *
                  100
                ).toFixed(0)}
                %
              </p>
            </div>
            <Close width={60} height={60} outline="#000" />
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-4 items-center rounded-2xl relative">
                <img
                  src={teamBImage || "https://picsum.photos/201"}
                  alt={teamBName}
                  className="rounded-2xl w-60 object-cover aspect-[3/4]"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-white/80 px-2 py-4">
                  <h3 className="text-2xl text-center font-century-gothic-bold text-gray-600">
                    {teamBName}
                  </h3>
                </div>
              </div>
              <p className="text-5xl font-century-gothic-bold text-gray-600 text-center">
                {(
                  (teamMatchStats.totalTeamB / teamMatchStats.totalGuests) *
                  100
                ).toFixed(0)}
                %
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-8 items-center">
            <div className="h-8 w-full rounded-full overflow-hidden flex">
              <div
                className="bg-primary-500 h-full"
                style={{
                  width: `${
                    (teamMatchStats.totalTeamA / teamMatchStats.totalGuests) *
                    100
                  }%`,
                }}
              />
              <div
                className="bg-blue-400 h-full"
                style={{
                  width: `${
                    (teamMatchStats.totalTeamB / teamMatchStats.totalGuests) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-start w-full">
            <div className="flex flex-col gap-4 w-60">
              {teamMatchStats.teamAGuests.map((result, index) => (
                <GuestCard key={index} result={result} />
              ))}
            </div>
            <div className="flex flex-col gap-4 w-60">
              {teamMatchStats.teamBGuests.map((guest) => (
                <GuestCard key={guest.id} result={guest} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const GuestCard = (props: { result: TTeamMatchResult }) => {
  const { result } = props;
  const [profileImage, setProfileImage] = useState<string | undefined>();

  useEffect(() => {
    const fetchProfileImage = async () => {
      if (result.guestPhotoStoragePath) {
        const url = await DigitalOceanSpaces.getFileUrl(
          result.guestPhotoStoragePath
        );
        setProfileImage(url);
      }
    };

    fetchProfileImage();
  }, [result]);

  return (
    <div className="flex flex-row gap-4 items-center">
      <img
        src={profileImage || userProfile}
        alt={result.guestName}
        className="rounded-full h-10 w-10 object-cover"
      />
      <h3 className="text-base font-century-gothic-bold text-gray-600">
        {result.guestName}
      </h3>
    </div>
  );
};
