import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ShowEvent from "../pages/admin/events/ShowEvent";
import Events from "../pages/admin/events/Events";
import AdminLayout from "../layouts/admin/AppLayout";
import EventLayout from "layouts/admin/EventLayout";
import CustomerAuthenticatedPanelLayout from "../layouts/public/AuthenticatedPanelLayout";
import CustomerUnauthenticatedLayout from "../layouts/public/UnauthenticatedLayout";
import EventActions from "../pages/admin/events/EventActions";
import CustomerHome from "../pages/public/Home";
import CustomerQRCode from "../pages/public/QRCode";
import CustomerEditEvent from "../pages/public/EditEvent";
import CustomerGallery from "../pages/public/Gallery";
import PublicGallery from "../pages/public/PublicGallery";
import CustomerServiceTerms from "../pages/public/ServiceTerms";
import CustomerLogin from "../pages/public/Login";
import CustomerForgotPassword from "../pages/public/ForgotPassword";
import CustomerChangePassword from "../pages/public/ChangePassword";
import CustomerNewAccount from "../pages/public/NewAccount";
import { getEvents, getEventById } from "../services/events";
import VideosEvent from "../pages/admin/events/VideosEvent";
import {
  getDataForVideoDownload,
  getVideosByEventId,
} from "../services/videos";
import Login from "../pages/admin/Login";
import VideosDownload from "../pages/download/VideosDownload";
import LogsEvent from "../pages/admin/events/LogsEvent";
import { getLogsByEventId } from "../services/logs";
import Home from "../pages/admin/Home";
import { getHomeData } from "../services/home";
import UploadsEvent from "../pages/admin/events/UploadsEvent";
import { uploadsLoader, uploadDownloadLoader } from "../services/uploads";
import CustomerEventNotFound from "../pages/public/EventNotFound";
import { adminAuthLoader, publicAuthLoader } from "../services/auth";
import UploadsDownload from "../pages/download/UploadsDownload";
import EventCreationLayout from "layouts/admin/EventCreationLayout";
import CreateEvent from "../pages/admin/events/CreateEvent";
import CreateEventForCustomerEdition from "../pages/admin/events/CreateEventForCustomerEdition";
import * as customerService from "../services/customer";
import Migration from "../pages/admin/Migration";
import NewFeatures from "../pages/admin/events/NewFeatures";
import Games from "../pages/public/Games";
import Quiz from "../pages/public/Games/Quiz";
import TeamMatch from "../pages/public/Games/TeamMatch";
import PhotoChallenge from "../pages/public/Games/PhotoChallenge";
export default function AppRouter() {
  const router = createBrowserRouter([
    {
      element: <AdminLayout />,
      loader: adminAuthLoader,
      path: "/admin",
      children: [
        {
          path: "",
          element: <Home />,
          loader: getHomeData,
        },
        {
          path: "events",
          element: <Events />,
          loader: getEvents,
        },
        {
          path: "events/create",
          element: <EventCreationLayout />,
          children: [
            {
              path: "",
              element: <CreateEvent />,
            },
            {
              path: "for-customer-edition",
              element: <CreateEventForCustomerEdition />,
            },
          ],
        },
        {
          path: "events/:id",
          element: <EventLayout />,
          loader: getEventById,
          children: [
            {
              path: "",
              element: <ShowEvent />,
            },
            {
              path: "new-features",
              element: <NewFeatures />,
            },
            {
              path: "actions",
              element: <EventActions />,
            },
            {
              path: "videos",
              element: <VideosEvent />,
              loader: getVideosByEventId,
            },
            {
              path: "uploads",
              element: <UploadsEvent />,
              loader: uploadsLoader,
            },
            {
              path: "logs",
              element: <LogsEvent />,
              loader: getLogsByEventId,
            },
          ],
        },
        {
          path: "migrate",
          element: <Migration />,
        },
      ],
    },
    {
      path: "/admin/login",
      element: <Login />,
    },
    {
      path: "/public",
      element: <CustomerUnauthenticatedLayout />,
      children: [
        {
          path: "change-password",
          element: <CustomerChangePassword />,
        },
      ],
    },
    {
      path: "/public/:eventCode",
      element: <CustomerUnauthenticatedLayout />,
      children: [
        {
          path: "login",
          element: <CustomerLogin />,
        },
        {
          path: "new-account",
          element: <CustomerNewAccount />,
        },
        {
          path: "forgot-password",
          element: <CustomerForgotPassword />,
        },
        {
          path: "event-not-found",
          element: <CustomerEventNotFound />,
        },
      ],
    },
    {
      path: "/public/:eventCode/gallery",
      element: <CustomerUnauthenticatedLayout showLeftImageInDesktop={false} />,
      children: [
        {
          path: "",
          element: <PublicGallery />,
          loader: customerService.eventWithUploadsForPublicGalleryLoader,
        },
      ],
    },

    {
      path: "/customer-panel/:eventCode",
      element: <CustomerAuthenticatedPanelLayout />,
      loader: publicAuthLoader,
      children: [
        {
          path: "",
          element: <CustomerHome />,
          loader: customerService.eventWithUploadsLoader,
        },
        {
          path: "edit",
          element: <CustomerEditEvent />,
          loader: customerService.eventLoader,
        },
        {
          path: "qrcode",
          element: <CustomerQRCode />,
          loader: customerService.eventLoader,
        },
        {
          path: "gallery",
          element: <CustomerGallery />,
          loader: customerService.eventWithUploadsLoader,
        },
        {
          path: "service-terms",
          element: <CustomerServiceTerms />,
        },
        {
          path: "games",
          loader: customerService.eventLoader,
          element: <Games />,
        },
        {
          path: "games/quiz",
          loader: customerService.eventQuizLoader,
          element: <Quiz />,
        },
        {
          path: "games/team-match",
          loader: customerService.eventTeamMatchLoader,
          element: <TeamMatch />,
        },
        {
          path: "games/photo-challenge",
          loader: customerService.eventPhotoChallengeLoader,
          element: <PhotoChallenge />,
        },
      ],
    },
    {
      path: "/download/:id",
      element: <VideosDownload />,
      loader: getDataForVideoDownload,
    },
    {
      path: "/download/upload/:id",
      element: <UploadsDownload />,
      loader: uploadDownloadLoader,
    },
  ]);

  return <RouterProvider router={router} />;
}
