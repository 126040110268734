import { TEvent, TEventWithUploads, TUpload } from "../../services/types";
import { useLoaderData } from "react-router-dom";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { SpinnerIcon } from "../../assets/svg";
import Card from "components/ui/Card";
import dayjs, { Dayjs } from "dayjs";
import { eventTitleFormatter } from "utils/event";
import classNames from "classnames";
import { createLog } from "../../services/logs";
import { getUploadsByEventId } from "../../services/uploads";
import { isEventReleased } from "../../services/events";
import { formatDate } from "utils/utils";
import { GalleryWithSlideshow } from "../../components/gallery";
import { MONTHS_UNTIL_CLOSE_DOWNLOADS } from "../../constants";

const buttonLabels = {
  initial: "Baixar arquivos",
  downloading: "Baixando <current>/<total> arquivos",
  done: "Download finalizado",
  error: "Continuar download",
};

export default function Gallery() {
  const event = useLoaderData() as TEventWithUploads;
  const eventTitle = eventTitleFormatter(event);
  const eventReleased = useMemo(() => isEventReleased(event), [event]);
  const unreleasedCard = useMemo(() => {
    const eventReleaseDate = dayjs(event.releaseDate);

    if (event.realtimeGallery) {
      return (
        <UnreleasedRealtimeGalleryCard
          filesCount={event.uploads.length}
          releaseDate={eventReleaseDate}
        />
      );
    }
    return <UnreleasedStandardGalleryCard releaseDate={eventReleaseDate} />;
  }, [event]);
  const galleryReleasead = useMemo(
    () => event.realtimeGallery || eventReleased,
    [event, eventReleased]
  );

  return (
    <div className="w-full flex flex-col items-center mb-16">
      <h1 className="text-3xl text-center font-playfair-display font-normal">
        {eventTitle}
      </h1>

      <div className="flex items-center justify-center m-10 px-8">
        {eventReleased ? (
          <DownloadCard event={event} filesCount={event.uploads.length} />
        ) : (
          unreleasedCard
        )}
      </div>

      {galleryReleasead && <GalleryWithSlideshow event={event} />}
    </div>
  );
}

type DownloadButtonProps = {
  onClick: () => void;
  status: "initial" | "downloading" | "done" | "error";
  progress?: Array<number>;
};

const DownloadButton = (props: DownloadButtonProps) => {
  const { onClick, status, progress = [] } = props;
  const disabled = status === "downloading" || status === "done";
  let label = buttonLabels[status];
  let bgColor;
  let hover;
  let isDisabled = false;
  let icon;

  switch (status) {
    case "downloading":
      bgColor = "bg-gray-400";
      isDisabled = true;

      icon = (
        <SpinnerIcon width={24} height={24} color="#ffffff" outline="#4b5563" />
      );

      // update the label with the current and total files
      const [current = 0, total = 0] = progress;

      if (total === 0) {
        label = "Iniciando download";
      } else {
        label = label
          .replace("<current>", current.toString())
          .replace("<total>", total.toString());
      }

      break;
    case "done":
      bgColor = "bg-green-400";
      isDisabled = true;
      break;
    default:
      bgColor = "bg-red-400";
      hover = "hover:bg-red-500";
      break;
  }

  const handleClick = async () => {
    onClick();
  };

  const btnClass = classNames(
    "button text-white text-lg font-century-gothic-bold px-4 py-6 flex justify-center",
    bgColor,
    hover,
    {
      "cursor-pointer": !isDisabled,
      "cursor-not-allowed": isDisabled,
    }
  );

  return (
    <button className={btnClass} onClick={handleClick} disabled={disabled}>
      {icon ? <span className="mr-2">{icon}</span> : null}
      {label}
    </button>
  );
};

type DownloadCardProps = { event: TEvent; filesCount: number };

const DownloadCard = (props: DownloadCardProps) => {
  const { event, filesCount } = props;
  const eventReleaseDate = dayjs(event.releaseDate);

  const [downloadStatus, setDownloadStatus] = useState<
    "initial" | "downloading" | "done" | "error"
  >("initial");
  const [downloadProgress, setDownloadProgress] = useState<[number, number]>([
    0, 0,
  ]);

  const expireDate = useMemo(() => {
    return eventReleaseDate.add(MONTHS_UNTIL_CLOSE_DOWNLOADS, "month");
  }, [eventReleaseDate]);

  const lastDownloadedFileKey = useMemo(() => {
    return `lastDownloadedFile-${event.id}`;
  }, [event]);

  const downloadLog = useCallback(async () => {
    const log = {
      eventId: event.id as string,
      message: `Clicou para fazer download.`,
    };
    await createLog(log);
  }, [event]);

  const getLastDownloadedFile = useCallback(
    () => localStorage.getItem(lastDownloadedFileKey),
    [lastDownloadedFileKey]
  );

  const removeLastDownloadedFile = useCallback(
    () => localStorage.removeItem(lastDownloadedFileKey),
    [lastDownloadedFileKey]
  );

  const saveLastDownloadedFile = useCallback(
    (filename: string) => localStorage.setItem(lastDownloadedFileKey, filename),
    [lastDownloadedFileKey]
  );

  // Check is the wasn't errors on the last visit
  useEffect(() => {
    if (getLastDownloadedFile() && downloadStatus === "initial") {
      console.debug("Download error found");

      setDownloadStatus("error");
    }
  }, [getLastDownloadedFile, downloadStatus]);

  const handleDownload = async () => {
    setDownloadStatus("downloading");

    const uploads = (await getUploadsByEventId(
      event.id as string
    )) as TUpload[];

    try {
      const lastDownloadedFile = getLastDownloadedFile();
      const lastDownloadedFileIndex = uploads.findIndex(
        (upload) => upload.fileName === lastDownloadedFile
      );
      const startIndex =
        lastDownloadedFileIndex > 0 ? lastDownloadedFileIndex + 1 : 0;

      await downloadAllFiles(uploads, startIndex);
    } catch (error) {
      setDownloadStatus("error");
    }
  };

  const downloadFile = async (upload: TUpload) => {
    const a = document.createElement("a");
    a.style.display = "none";

    const { fileStoragePath, convertedFileStoragePath } = upload;

    let publicTmpUrl, filename;

    if (convertedFileStoragePath) {
      publicTmpUrl = await DigitalOceanSpaces.getFileUrl(
        encodeURIComponent(convertedFileStoragePath)
      );
      filename = convertedFileStoragePath.split("/").pop() as string;
    } else {
      publicTmpUrl = await DigitalOceanSpaces.getFileUrl(
        encodeURIComponent(fileStoragePath)
      );
      filename = upload.fileName;
    }

    const response = await fetch(publicTmpUrl);
    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    a.href = objectUrl;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(objectUrl);
  };

  const downloadAllFiles = async (
    uploads: TUpload[],
    startIndex: number = 0
  ) => {
    if (!uploads) return;

    for (let i = startIndex; i < uploads.length; i++) {
      const upload = uploads[i];

      console.debug(
        `Downloading file ${i + 1} of ${uploads.length}. Current file: ${
          upload.fileName
        }`
      );

      await downloadFile(uploads[i]);

      saveLastDownloadedFile(upload.fileName);

      setDownloadProgress([i + 1, uploads.length]);
    }

    // clear the download status
    setDownloadStatus("done");
    removeLastDownloadedFile();
  };

  const handleDownloadReset = async () => {
    removeLastDownloadedFile();
    await handleDownload();
  };

  return (
    <Card className="w-full h-full flex flex-col px-6 py-8 items-center justify-center cursor-pointer">
      <div className="font-century-gothic space-y-5 mb-5 px-4">
        <p className="text-center text-lg leading-8">Seu ábum está liberado!</p>
        <p className="text-center text-lg leading-8">
          Você recebeu{" "}
          <span className="font-century-gothic-bold">
            {filesCount} arquivos!
          </span>
        </p>
        <p className="text-center text-lg leading-8">
          Seus arquivos estão disponíveis para download até o dia:{" "}
          <span className="font-century-gothic-bold">
            {formatDate(expireDate)}
          </span>
          .
        </p>

        <p className="text-center text-lg leading-8">
          Salve seus arquivos em um dispositivo próprio (ex: drive, HD externo,
          etc).
        </p>
      </div>

      <div onClick={downloadLog}>
        <DownloadButton
          onClick={handleDownload}
          status={downloadStatus}
          progress={downloadProgress}
        />
      </div>

      {["done", "error"].includes(downloadStatus) && (
        <p className="text-sm mt-5">
          Caso você deseje reiniciar o processo de download,{" "}
          <span
            className="underline hover:no-underline focus:outline-none cursor-pointer"
            onClick={handleDownloadReset}
          >
            clique aqui
          </span>
          .
        </p>
      )}
    </Card>
  );
};

type UnreleasedRealtimeGalleryCardProps = {
  filesCount: number;
  releaseDate: Dayjs;
};

const UnreleasedRealtimeGalleryCard = (
  props: UnreleasedRealtimeGalleryCardProps
) => {
  const { filesCount, releaseDate } = props;

  return (
    <Card className="max-w-[600px] px-6 py-8 flex flex-col justify-center items-center gap-4 text-lg text-center">
      <span>
        Estamos <strong>aguardando seus convidados</strong> terminarem de enviar
        suas fotos!
      </span>
      <span className="flex flex-col items-center gap-4">
        Até o momento você recebeu:
        <strong className="py-0.5 px-5 bg-rose-100">
          {filesCount} arquivos!
        </strong>
      </span>
      <span className="flex flex-col items-center gap-4">
        Você poderá fazer o download do álbum no dia:
        <strong className="py-0.5 px-5 bg-rose-100">
          {formatDate(releaseDate)}
        </strong>
      </span>
      <span>
        Até lá você pode baixar as suas fotos preferidas individualmente,
        clicando na foto escolhida.
      </span>
    </Card>
  );
};

type UnreleasedStandardGalleryCardProps = {
  releaseDate: Dayjs;
};

const UnreleasedStandardGalleryCard = (
  props: UnreleasedStandardGalleryCardProps
) => {
  const { releaseDate } = props;

  return (
    <Card className="max-w-[600px] px-6 py-8 flex flex-col justify-center items-center gap-4 text-lg text-center">
      <span>
        Estamos <strong>preparando sua Cápsula do Tempo</strong> com as fotos,
        vídeos e recados dos seus convidados!
      </span>
      <span className="flex flex-col items-center gap-4">
        Seu álbum será liberado dia
        <strong className="py-0.5 px-5 bg-rose-100">
          {formatDate(releaseDate)}
        </strong>
      </span>
      <span>Volte aqui nessa data para se emocionar! &lt;3</span>
    </Card>
  );
};
