import { TEvent } from "@services/types";
import {
  BINGO_FEATURE_FLAG,
  QUIZ_FEATURE_FLAG,
  PHOTO_CHALLENGE_FEATURE_FLAG,
  TEAM_MATCH_FEATURE_FLAG,
} from "../../../constants";
import { useMemo } from "react";
import { Link, useLoaderData, useParams } from "react-router-dom";
import bingoIcon from "../../../assets/images/bingo-black-icon.png";
import quizIcon from "../../../assets/images/quiz-black-icon.png";
import photoChallengeIcon from "../../../assets/images/photo-challenge-black-icon.png";
import teamMatchIcon from "../../../assets/images/team-match-black-icon.png";

export default function Games() {
  const event = useLoaderData() as TEvent;
  const { eventCode } = useParams();

  const bingoEnabled = useMemo(
    () => event?.betaFeatures?.includes(BINGO_FEATURE_FLAG),
    [event]
  );
  const quizEnabled = useMemo(
    () => event?.betaFeatures?.includes(QUIZ_FEATURE_FLAG),
    [event]
  );
  const photoChallengeEnabled = useMemo(
    () => event?.betaFeatures?.includes(PHOTO_CHALLENGE_FEATURE_FLAG),
    [event]
  );
  const teamMatchEnabled = useMemo(
    () => event?.betaFeatures?.includes(TEAM_MATCH_FEATURE_FLAG),
    [event]
  );

  return (
    <div className="max-w-screen-xl w-full mx-auto pb-16">
      <div className="flex flex-col justify-center items-center gap-10 mb-10 px-10 relative">
        <h1 className="text-3xl text-center font-playfair-display font-normal">
          Jogos
        </h1>
        <div className="flex items-center justify-center flex-wrap gap-4">
          {quizEnabled && (
            <GameCard
              image={quizIcon}
              title="Quiz"
              link={`/customer-panel/${eventCode}/games/quiz`}
            />
          )}
          {teamMatchEnabled && (
            <GameCard
              image={photoChallengeIcon}
              title="Foto Desafio"
              link={`/customer-panel/${eventCode}/games/photo-challenge`}
            />
          )}
          {photoChallengeEnabled && (
            <GameCard
              image={teamMatchIcon}
              title="Chapéu Seletor"
              link={`/customer-panel/${eventCode}/games/team-match`}
            />
          )}
          {bingoEnabled && (
            <GameCard
              image={bingoIcon}
              title="Bingo"
              link={`/customer-panel/${eventCode}/games/bingo`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const GameCard = ({
  image,
  title,
  link,
}: {
  image: string;
  title: string;
  link: string;
}) => {
  return (
    <Link
      to={link}
      className="flex flex-col items-center justify-center relative rounded-2xl overflow-hidden w-64 h-64 group"
    >
      <img src={image} alt={title} className="w-full h-full object-cover" />
      <div className="absolute bottom-0 bg-black/70 transition-all cursor-pointer duration-300 w-full h-full flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold text-white">{title}</h2>
      </div>
    </Link>
  );
};
