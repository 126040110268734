import { useLoaderData } from "react-router-dom";
import userProfile from "../../../assets/images/user-profile.png";
import { TCustomerEventQuizLoader } from "@services/customer";

export default function Quiz() {
  const { event, quizRank = [] } = useLoaderData() as TCustomerEventQuizLoader;
  const totalQuestions = event?.quiz?.length ?? 0;

  return (
    <div className="max-w-screen-xl w-full mx-auto pb-16">
      <div className="flex flex-col justify-center items-center gap-10 mb-10 px-10 relative">
        <h1 className="text-3xl text-center font-playfair-display font-normal">
          Quiz
        </h1>

        <h3 className="text-base text-center font-century-gothic-bold text-gray-600">
          Ranking dos convidados que mais conhecem os noivos!
        </h3>

        {quizRank.length === 0 ? (
          <h4>Nenhum quiz respondido até o momento.</h4>
        ) : (
          <div className="flex flex-col divide-y divide-gray-200 bg-white rounded-2xl p-4 shadow-md w-9/12">
            {quizRank.map((rank) => (
              <div
                key={rank.guestId}
                className="flex flex-row gap-8 py-4 first:pt-0 last:pb-0 items-center"
              >
                <div className="flex flex-row gap-4 items-center rounded-full bg-primary-500 w-16 h-16 justify-center">
                  <p className="text-3xl font-century-gothic-bold text-white">
                    {rank.rank}
                  </p>
                </div>
                <div className="flex flex-col gap-2 grow">
                  <h2>{rank.guestName}</h2>
                  <div className="flex flex-row gap-2 justify-between">
                    <p className="text-base text-gray-600">
                      Acertos: {rank.score}/{totalQuestions}
                    </p>
                    <p className="text-base text-gray-600">
                      Tempo: {formatTime(rank.timeSpentInSeconds)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row gap-4 items-center rounded-2xl">
                  <img
                    src={rank.avatar || userProfile}
                    alt={rank.guestName}
                    className="rounded-2xl h-20 w-20 object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const formatTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes} min ${seconds} s`;
};
